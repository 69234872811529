let markAllAsRead = $('.mark-all-as-read');
let markAsRead = $('.mark-as-read');

markAllAsRead.one('click', function () {
    let that = this;
    let url = $(this).data('mark-all-as-read-url');
    let unreadNotifications = $('.notification:not(.read)');

    markAllAsRead.attr('disabled', 'disabled');
    $('#all-read-tooltip').addClass('hidden');
    unreadNotifications.find('.state-marker').removeClass('bg-primary-green').addClass('bg-gray-500');
    markAsRead.removeClass(['text-primary-green', 'hover:text-primary-greenLighter', 'cursor-pointer']).addClass('text-gray-500');

    if( unreadNotifications.length > 0 ) {
        $.ajax({
            url: url,
            success: function (data) {},
            error: function (data, xhr, status, error) {},
        });
    }
});

markAsRead.one('click', function () {
    let that = this;
    let notificationContainer = $(this).closest('.notification');
    let notificationId = notificationContainer.data('notification-id');

    // Change the UI state no matter if the ajax succeeds or fails
    $('.notification[data-notification-id="' + notificationId + '"] .state-marker').removeClass('bg-primary-green').addClass('bg-gray-500');
    $(that).removeClass(['text-primary-green', 'hover:text-primary-greenLighter', 'cursor-pointer']).addClass('text-gray-500')

    if(!notificationContainer.hasClass('read')) {
        let url = $(this).data('mark-as-read-url');

        $.ajax({
            url: url,
            success: function (data) {},
            error: function (data, xhr, status, error) {},
        });
    }
});
