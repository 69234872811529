function readURL(input) {
    let targetSelector = '#uploaded-img';
    let targetElement = $(targetSelector);
    let targetClass = targetElement.data('upload-preview-class') || 'pb-3 w-full';

    if (input.files && input.files[0]) {
        var reader = new FileReader();

        reader.onload = function (e) {
            targetElement.html('<img class="' + targetClass + '" src="  ' + e.target.result + ' " />')
        }

        reader.readAsDataURL(input.files[0]);
    }
}

$("#file-upload-img").change(function(){
    readURL(this);
});

