// Function for text-area count
(function($) {
    $.fn.charCount = function(element){
        let options = {
            allowed: 500,
            warning: 25,
            'css-warning': 'text-yellow-400',
            'css-exceeded': 'text-red-600',
        };

        let jqElement = $(element);
        let dataAllowed = jqElement.data('char-counter-allowed');
        let dataWarning = jqElement.data('char-counter-warning');
        let dataCssWarning = jqElement.data('char-counter-css-warning');
        let dataCssExceeded = jqElement.data('char-counter-css-exceeded');

        // Extend defaults
        if( dataAllowed ) {
            options.allowed = dataAllowed;
        }

        if( dataWarning ) {
            options.warning = dataWarning;
        }

        if( dataCssWarning ) {
            options['css-warning'] = dataCssWarning;
        }

        if( dataCssExceeded ) {
            options['css-exceeded'] = dataCssExceeded;
        }

        function calculate(){
            let available = options.allowed - $(element).val().length;

            let countContainerSelector = $(element).data('char-counter-target');
            let countContainer = $(countContainerSelector);

            if(available <= options.warning && available >= 0){
                countContainer.addClass(options['css-warning']);
            } else {
                countContainer.removeClass(options['css-warning']);
            }

            if(available < 0){
                countContainer.addClass(options['css-exceeded']);
            } else {
                countContainer.removeClass(options['css-exceeded']);
            }

            countContainer.html(available);
        }

        calculate();
        jqElement.keyup(calculate);
        jqElement.change(calculate);

    };
})(jQuery);


$(document).ready(function() {
    $('[data-toggle="char-counter"]').each(function() {
        $(this).charCount(this);
    });
});

